import axios from "axios";

async function UpdateNotification(notificationId: string, user: any): Promise<any> {
    const URL = process.env.NEXT_PUBLIC_DEVELOPMENT === "development" ? "https://europe-west1-nestelop-test.cloudfunctions.net/update_notifications" : "https://europe-west1-nestelop-production.cloudfunctions.net/update_notifications";
    const token = await user.auth.currentUser?.getIdToken();

    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
    };

    const data: any = {
        notificationId: notificationId,
    };

    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        timeout: 4000,
        data: data,
    });

    const response = promise.then((response: any) => response).catch((e) => e);
    return response;
}

export { UpdateNotification };
