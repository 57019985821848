import { UpdateNotification } from "@/HttpCalls/UpdateNotification";

const setExternalUserId = async (user_id: string) => {
    try {
        const OneSignal = (await import("react-onesignal")).default;
        OneSignal.setExternalUserId(user_id);
    } catch {
        console.log("Could not set external Id");
    }
};

const triggerAfterClickOnEvent = async (user: any) => {
    
    try {
        const OneSignal = (await import("react-onesignal")).default;
        OneSignal.addListenerForNotificationOpened((event: any) => {
            const notificationData = event.data;
            if (notificationData && Object.keys(notificationData).includes("notificationId")) {
                UpdateNotification(notificationData["notificationId"], user)
            }
        });
    } catch {
        console.log("Could not set external Id");
    }
};

export { setExternalUserId, triggerAfterClickOnEvent };
