import { sleep } from "@/helpers/sleep";
import { GetMostViewed } from "@/HttpCalls/Piwik/piwikRequests";
import { FindRunsRequest } from "@/HttpCalls/runs/FindRunRequest";
import { SpecialEvents, raceType, surface } from "./constants/categories";

function sortOnKeys(dict: any) {
    const sorted = [];
    for (const key in dict) {
        sorted[sorted?.length] = key;
    }
    sorted.sort();

    const tempDict: any = {};
    if (sorted) {
        for (let i = 0; i < sorted?.length; i++) {
            tempDict[sorted[i]] = dict[sorted[i]];
        }
    }

    return tempDict;
}

function sortObj(obj: any) {
    // Sort object as list based on values
    const sorted: any[] = Object.keys(obj)
        .map((k) => [k, obj[k]])
        .sort((a, b) => b[1] - a[1]);
    const tempDict: any = {};
    if (sorted) {
        for (let i = 0; i < sorted?.length; i++) {
            tempDict[sorted[i][0]] = sorted[i][1];
        }
    }

    return tempDict;
}

function sortNestedObj(obj: any) {
    const sortDict: any = {};
    for (const key of Object.keys(obj)) {
        const numberOfEvents = Object.values(obj[key]).reduce((a: any, b: any) => a + b, 0);
        sortDict[key] = numberOfEvents;
    }

    const sorted: any[] = Object.keys(sortDict)
        .map((k) => [k, sortDict[k]])
        .sort((a, b) => b[1] - a[1]);
    const tempDict: any = {};
    if (sorted) {
        for (let i = 0; i < sorted?.length; i++) {
            tempDict[sorted[i][0]] = obj[sorted[i][0]];
        }
    }

    return tempDict;
}

function InsertAndDelete(newDict: any, oldDict: any, key: string) {
    if (Object.keys(oldDict).includes(key)) {
        newDict[key] = oldDict[key];
        delete oldDict[key];
    }
}

const filterData = async (data: any, county: any) => {
    let distanceDict: any = {};
    let type: any = {};
    const MostViewed: any = {};
    // Populating distance dict
    distanceDict = data["length"];

    // Populate most viewed
    for (let i = 0; i < 5; i++) {
        try {
            const response = await GetMostViewed();
            if (response.status === 200) {
                const data = response?.data;
                const runs = [];
                const matchString = data.map((item: string) => "'" + item + "'");
                let offset = 0;
                const result = await FindRunsRequest(
                    "",
                    "post_title IN [" + matchString + "]",
                    offset,
                    ""
                );
                runs.push(...result.data["hits"]);
                for (const view of data) {
                    const match = runs.find((run: any) => run.post_title === view);
                    if (match != undefined) {
                        MostViewed[view] = match["post_url"];
                    }
                }
                break;
            } else if (response.status === 400) {
                await sleep(1000);
            }
        } catch {
            await sleep(1000);
        }
    }

    // Populate country & county
    const countyOptions = county["options"];
    let newCountryDict: any = {};
    for (const key of Object.keys(data["country"])) {
        newCountryDict[key] = {};

        const test = countyOptions.find((e: any) => e.optionId === key);
        const counties = Object.keys(data["county"]).filter((e: any) =>
            test?.subOptions?.includes(e)
        );
        for (const region of counties) {
            newCountryDict[key][region] = data["county"][region];
        }
    }

    // Populate race type and surface
    type = data["race_type"];
    let special: any = {};
    for (const key in SpecialEvents) {
        InsertAndDelete(special, type, SpecialEvents[key]);
    }

    let surfaceTypes: any = {};
    for (const key in surface) {
        InsertAndDelete(surfaceTypes, data["terrain_type"], surface[key]);
    }

    let race: any = {};
    for (const key in raceType) {
        InsertAndDelete(race, type, raceType[key]);
    }

    type = sortObj(type);
    special = sortObj(special);
    surfaceTypes = sortObj(surfaceTypes);
    //race = sortObj(race);

    newCountryDict = sortNestedObj(newCountryDict);
    for (const key in newCountryDict) {
        newCountryDict[key] = sortOnKeys(newCountryDict[key]);
    }
    return {
        distance: distanceDict,
        type: type,
        country: newCountryDict,
        mostViewed: MostViewed,
        Specail: special,
        SurfaceTypes: surfaceTypes,
        Race: race,
    };
};
export { filterData };
