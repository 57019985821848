import { shuffle } from "@/helpers/shuffler";
import { UnpackBlogDataMeili } from "@/helpers/blog/unpackBlogData";
import { getLatestBlogs } from "@/HttpCalls/blogs/getBlogs";
import { GetFrontpageCarousel, GetPromotionrequest } from "@/HttpCalls/runs/GetPartnersRequest";

export const fetchPromotionData = async (firebase: any) => {
    const result = await GetPromotionrequest();
    if (result.status === 200) {
        return result.data;
    }
};



export const getFrontpageCarouselData = async (firebase: any) => {
    let carousel_data = [];
    let result = await GetFrontpageCarousel();
    if (result.status === 200) {
        carousel_data = result.data;
        carousel_data = shuffle(carousel_data);
    }
    return carousel_data;
};

export const fetchBlogg = async (firebase: any, limit?:number) => {
    let result = await getLatestBlogs();
    let blogs = await UnpackBlogDataMeili(result.data, firebase, false, limit);

    return blogs;
};
