import firebaseTriggerUrls from "../../constants/firebaseTriggers";
import axios from "axios";

async function GetMostViewed(): Promise<any> {
    const URL = firebaseTriggerUrls.MostViewed;

    const headers = {
        Accept: "application/json",
    };

    const promise = axios({
        headers: headers,
        url: URL,
        method: "get",
        timeout: 5000,
    });

    const response = promise
        .then((response: any) => response)
        .catch(() => ({ status: 400 }));
    return response;
}

export { GetMostViewed };