const SpecialEvents = [
  "Hinderløp/OCR",
  "Triatlon",
  "Stafett",
  "Virtuelt",
  "Hundeløp",
  "Trugeløp"
];

const surface = [
  "Asfalt",
  "Bane",
  "Grus",
  "Snø",
  "Terreng",
  "Sand",
  "Gress",
];

const raceType = [
  "Gateløp",
  "Terrengløp",
  "Stiløp",
  "Motbakke",
  "Etappeløp",
  "Baneløp",
  "Karusell",
  "Repeterende",
  "Cannonball",
  "Timeløp",
  "Backyard ultra",
  "Barneløp"
];


export { SpecialEvents, surface, raceType };
