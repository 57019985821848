import isDate from "date-fns/isDate";
import parse from "date-fns/parse";

const convertDateString_NorwegianToLocal = (datestring: string, options?: any) => {
  //Input is datestring on RA-format "dd.mm.yyyy"
  const date_string_array = datestring.split(".");
  const local_date = new Date(
    parseInt(date_string_array[2]),
    parseInt(date_string_array[1]) - 1,
    parseInt(date_string_array[0])
  );

  // Use browser's language setting if they are set
  if (typeof navigator !== "undefined") {
    return local_date.toLocaleDateString(navigator.languages[0], options);
  }
  else {
    return local_date.toLocaleDateString();
  }
};

const convertDateString_EnglishToLocal = (datestring: string) => {
  //Input is datestring on standard format
  const local_date = new Date(datestring);

  // Use browser's language setting if they are set
  if (typeof navigator !== "undefined") {
    return local_date.toLocaleDateString(navigator.languages[0]);
  }
  else {
    return local_date.toLocaleDateString();
  }
};

const parseDateString = (originalValue: any) => {
  if (originalValue === "") {
    return new Date();
  }
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, "yyyy-MM-dd", new Date());
  return parsedDate;
};

const timestampToMillis = (timestamp: any) => {
  if (timestamp) {
    if (timestamp._seconds) {
      return timestamp._seconds * 1000;
    }
    else if (timestamp.seconds) {
      return timestamp.seconds * 1000;
    }
    else if (isDate(timestamp)) {
      return timestamp.getTime();
    }
    else {
      return 0;
    }
  }
  else {
    return 0;
  }
}

const premium_dates = (premium_modules: any, runCardDate: any) => {

  const raceDate = !Array.isArray(runCardDate) ? runCardDate?.split(".") : runCardDate[0]?.split(".");
  const today = new Date().setHours(0, 0, 0, 0);

  const earlybird_active = premium_modules?.earlybird &&
    (today < timestampToMillis(premium_modules?.earlybird_close));
  const countdown_active = premium_modules?.countdown &&
    (today > timestampToMillis(premium_modules?.countdown_start)) &&
    (today < (new Date(raceDate[2], raceDate[1] - 1, raceDate[0])).getTime());
  const countdown_days = Math.round(((new Date(raceDate[2], raceDate[1] - 1, raceDate[0])).getTime()
    - today) / (1000 * 60 * 60 * 24));

  return [earlybird_active, countdown_active, countdown_days]
}
export { convertDateString_NorwegianToLocal, convertDateString_EnglishToLocal, parseDateString, timestampToMillis, premium_dates };
