// TODO: should we create a specific interface for this method?
function shuffle(arr: any) {
  // randomly rearranges the items in an array
  const result = [];
  for (let i = arr.length - 1; i >= 0; i--) {
    // picks an integer between 0 and i:
    const r = Math.floor(Math.random() * (i + 1));
    // inserts the arr[i] element in the r-th free space in the shuffled array:
    for (let j = 0, k = 0; j <= arr.length - 1; j++) {
      if (result[j] === undefined) {
        if (k === r) {
          result[j] = arr[i]; // NOTE: if array contains objects, this doesn't clone them! Use a better clone function instead, if that is needed.
          break;
        }
        k++;
      }
    }
  }
  return result;
}

export { shuffle };
