import { useEffect, useState } from "react";

function on(obj:any, ...args:any) {
    obj.addEventListener(...args);
}

function off(obj:any, ...args:any) {
    obj.removeEventListener(...args);
}

/**
 * useScrollingUp custom react hook
 * @returns {boolean}
 */
const useScrollingUp = () => {
    let prevScroll:any;
    //if it is SSR then check you are now on the client and window object is available
    if (typeof window !== "undefined") {
        prevScroll = window.pageYOffset;
    }
    const [scrollingUp, setScrollingUp] = useState(true);
    
    const handleScroll = () => {
        const currScroll = window.pageYOffset;
        const isScrolled = prevScroll > currScroll || window.pageYOffset < 170;
        setScrollingUp(isScrolled);
        prevScroll = currScroll;
    };
    useEffect(() => {
        on(window, "scroll", handleScroll, { passive: true });
        return () => {
            off(window, "scroll", handleScroll, { passive: true });
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return scrollingUp;
};

export default useScrollingUp;
