import firebaseTriggerUrls from "../../constants/firebaseTriggers";
import axios from "axios";

async function GetPartnerRequest(): Promise<any> {
    const URL = firebaseTriggerUrls.getPartners;

    const headers = {
        Accept: "application/json",
    };

    
    const promise = axios({
        headers: headers,
        url: URL,
        method: "get",
        timeout: 5000,
    });
    return await promise
        .then((response: any) => response)
        .catch(() => ({ status: 444 }));

}

async function GetBlogsForRunnersRequest(): Promise<any> {
    const URL = 
    process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
        ? "https://cloudrun-x2lc2wibla-ew.a.run.app/get_blogs_for_runners"
        : "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_blogs_for_runners";


    const headers = {
        Accept: "application/json",
    };

    
    const promise = axios({
        headers: headers,
        url: URL,
        method: "get",
        timeout: 5000,
    });
    return await promise
        .then((response: any) => response)
        .catch(() => ({ status: 444 }));

}

async function GetBlogsForOrganizersRequest(): Promise<any> {
    const URL = 
    process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
        ? "https://cloudrun-x2lc2wibla-ew.a.run.app/get_blogs_for_organizers"
        : "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_blogs_for_organizers";


    const headers = {
        Accept: "application/json",
    };

    
    const promise = axios({
        headers: headers,
        url: URL,
        method: "get",
        timeout: 5000,
    });
    return await promise
        .then((response: any) => response)
        .catch(() => ({ status: 444 }));

}

async function GetPromotionrequest(): Promise<any> {
    const URL =
        process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
            ? "https://cloudrun-x2lc2wibla-ew.a.run.app/get_promotion"
            : "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_promotion";

    const headers = {
        Accept: "application/json",
    };

    
    const promise = axios({
        headers: headers,
        url: URL,
        method: "get",
        timeout: 5000,
    });
    return await promise
        .then((response: any) => response)
        .catch(() => ({ status: 444 }));

}
async function GetCloseToYou(lat:number, lon:number): Promise<any> {
    const URL =
        process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
            ? "https://cloudrun-x2lc2wibla-ew.a.run.app/get_close_runs"
            : "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_close_runs";

    const headers = {
        Accept: "application/json",
    };

    const data = {
        latitude: lat,
        longitude:lon
    }
    
    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        data: data,
        timeout: 5000,
    });
    return await promise
        .then((response: any) => response)
        .catch(() => ({ status: 444 }));

}

async function GetFrontpageCarousel(): Promise<any> {
    const URL =
        process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
            ? "https://cloudrun-x2lc2wibla-ew.a.run.app/get_frontpage_carousel"
            : "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_frontpage_carousel";

    const headers = {
        Accept: "application/json",
    };

    
    const promise = axios({
        headers: headers,
        url: URL,
        method: "get",
        timeout: 5000,
    });
    return await promise
        .then((response: any) => response)
        .catch(() => ({ status: 444 }));

}


export { GetPartnerRequest, GetPromotionrequest, GetFrontpageCarousel, GetCloseToYou, GetBlogsForRunnersRequest, GetBlogsForOrganizersRequest};
