import firebaseTriggerUrls from "../../constants/firebaseTriggers";
import axios from "axios";

async function GetSubMenuData(): Promise<any> {
    const URL = firebaseTriggerUrls.getSubmenuData;

    const headers = {
        Accept: "application/json",
    };

    const promise = axios({
        headers: headers,
        url: URL,
        method: "get",
        timeout: 10000,

    });
    return await promise
        .then((response: any) => response)
        .catch(() => ({ status: 444 }));

}

export { GetSubMenuData };