import firebaseTriggerUrls from "../../constants/firebaseTriggers";
import axios from "axios";

async function FindRunsRequest(
    query: string,
    filterOptions: string,
    offset = 0,
    sort = "",
    limit = 20
): Promise<any> {
    const URL = firebaseTriggerUrls.findRuns;
    const headers = {
        Accept: "application/json",
    };

    const data = {
        search: query,
        filter: filterOptions,
        offset: offset,
        sort: sort,
        limit: limit,
    };
    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        timeout: 5000,
        data: data,
    });
    return await promise
        .then((response: any) => response)
        .catch((e) => {
            return { status: 444 };
        });
}

async function get_list_of_runs_request(runs: string[]): Promise<any> {
    const URL =
        process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
            ? "https://cloudrun-x2lc2wibla-ew.a.run.app/list_of_runs"
            : "https://cloudrun-pgjjiy2k6a-ew.a.run.app/list_of_runs";
    const headers = {
        Accept: "application/json",
    };

    const data = {
        runs: runs,
    };
    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        timeout: 5000,
        data: data,
    });
    return await promise.then((response: any) => response).catch(() => ({ status: 444 }));
}

async function get_race_data(post_url: string, date?: string): Promise<any> {
    const URL =
        process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
            ? "https://cloudrun-x2lc2wibla-ew.a.run.app/get_current_run"
            : "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_current_run";
    const headers = {
        Accept: "application/json",
    };

    const data = {
        post_url: post_url,
        date: date ? date : "",
    };
    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        timeout: 3000,
        data: data,
    });
    return await promise.then((response: any) => response).catch(() => ({ status: 444 }));
}

async function get_map_data(race_guid: string): Promise<any> {
    const URL =
        process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
            ? "https://cloudrun-x2lc2wibla-ew.a.run.app/get_map_content"
            : "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_map_content";

    const headers = {
        Accept: "application/json",
    };

    const promise = axios({
        headers: headers,
        url: URL + "?race_guid=" + race_guid,
        method: "post",
        timeout: 5000,
    });
    return await promise.then((response: any) => response).catch(() => ({ status: 444 }));
}

async function get_bib_list(): Promise<any> {
    const URL =
        process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
            ? "https://cloudrun-x2lc2wibla-ew.a.run.app/get_bib_runs"
            : "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_bib_runs";
    const headers = {
        Accept: "application/json",
    };

    const promise = axios({
        headers: headers,
        url: URL,
        method: "get",
        timeout: 5000,
    });
    return await promise.then((response: any) => response).catch(() => ({ status: 444 }));
}

async function get_marathons(): Promise<any> {
    const URL =
        process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
            ? "https://cloudrun-x2lc2wibla-ew.a.run.app/get_maraton_runs"
            : "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_maraton_runs";
    const headers = {
        Accept: "application/json",
    };

    const promise = axios({
        headers: headers,
        url: URL,
        method: "get",
        timeout: 5000,
    });
    return await promise.then((response: any) => response).catch(() => ({ status: 444 }));
}

async function GetMapdataRequest(
    query: string,
    filterOptions: string,
    offset = 0,
    sort = "",
    limit = 20
): Promise<any> {
    const URL = firebaseTriggerUrls.getMapData;

    const headers = {
        Accept: "application/json",
    };

    const data = {
        search: query,
        filter: filterOptions,
        offset: offset,
        sort: sort,
        limit: limit,
    };
    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        timeout: 5000,
        data: data,
    });
    return await promise.then((response: any) => response).catch(() => ({ status: 444 }));
}

async function get_runs(query: string, offset: number, limit: number, token: any): Promise<any> {
    const URL =
        process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
            ? "https://cloudrun-x2lc2wibla-ew.a.run.app/get_runs_search"
            : "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_runs_search";
    const headers = {
        Accept: "application/json",
        Authorization: "Bearer " + token,
    };

    const data = {
        query: query,
        offset: offset,
        limit: limit,
    };
    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        timeout: 5000,
        data: data,
    });
    return await promise
        .then((response: any) => response)
        .catch((e: any) => {
            console.log(e);
            return { status: 444 };
        });
}

async function change_verified(race_guid: string, verified: boolean, token: any): Promise<any> {
    const URL =
        process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
            ? "https://cloudrun-x2lc2wibla-ew.a.run.app/change_verified"
            : "https://cloudrun-pgjjiy2k6a-ew.a.run.app/change_verified";
    const headers = {
        Accept: "application/json",
        Authorization: "Bearer " + token,
    };

    const data = {
        race_guid: race_guid,
        verified: verified
    };
    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        timeout: 5000,
        data: data,
    });
    return await promise
        .then((response: any) => response)
        .catch((e: any) => {
            console.log(e);
            return { status: 444 };
        });
}

export {
    FindRunsRequest,
    GetMapdataRequest,
    get_list_of_runs_request,
    get_bib_list,
    get_marathons,
    get_race_data,
    get_map_data,
    get_runs,
    change_verified
};
