import { useEffect, useState } from "react";
import * as ROUTES from "@/constants/routes";

import { ReactComponent as Monogram } from "@/assets/RunAgain_Monogram_Orange.svg";
import useTranslation from "next-translate/useTranslation";
import { ReactComponent as Menu } from "@/assets/menu.svg";
import { GetSubMenuData } from "@/HttpCalls/runs/GetSubMenuData";
import { filterData } from "@/components/SubMenu/filterRuns";
import { countryOptions } from "@/constants/countryConstants";
import { withFirebase } from "../Firebase";
import { Box, Grid, IconButton, Modal } from "@mui/material";
import dynamic from "next/dynamic";

import useScrollingUp from "@/helpers/useScrollingUp";
import {
    GetBlogsForOrganizersRequest,
    GetBlogsForRunnersRequest,
} from "@/HttpCalls/runs/GetPartnersRequest";
import { fetchBlogg } from "@/utils/frontPage/getFirebaseData";
import { setExternalUserId } from "@/helpers/push";

import { useRouter } from "next/router";

const SearchComponent: any = dynamic(() => import("./Search/searchComponent"));
const RegisterComponent: any = dynamic(() => import("@/content/login/login"), { ssr: false });
const DrawerMenu: any = dynamic(() => import("./DrawerMenu"), { ssr: false });

const classes = {
    root: {
        position: "absolute",
        top: "0",
        left: "0",
        zIndex: 1000,
        paddingTop: "0px",
        margin: "0px",
        width: "100%",
        borderRadius: "0px 0px 10px 10px",
        boxShadow:
            "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
        // display: { lg: "none", md: "block" },
    },
    SearchIcon: {
        color: "#FCFCFC",
        fontSize: "40px",
        width: "32px",
    },
    navigation: {
        borderRadius: "0px 0px 10px 10px",
        backgroundColor: "#FCFCFC",
    },
    numberOfAlerts: {
        backgroundColor: "#be5618",
        position: "relative",
        minWidth: "20px",
        width: "fit-content",
        color: "white",
        fontSize: "14px",
        padding: "0px 7px",
        borderRadius: "50px",
        margin: "auto",
        zIndex: 1,
        top: "-10px",
        left: "-15px",
    },
    showHeader: {
        position: "fixed",
        top: 0,
        opacity: 1,
        // animation: `${fadeIn} 0.3s ease-in-out`,
    },
    hideHeader: {
        // animation: `${fadeOut} 0.3s ease-in-out`,
        // opacity: 0,
    },
};

const MobileHeader = (props: any) => {
    const [login, setLogin] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [featuredRuns, setFeaturedRuns] = useState<any>(null);
    const [runners_news, set_runners_news] = useState<null | any[]>(null);
    const [organizer_news, set_organizer_news] = useState<null | any[]>(null);
    const [news, set_news] = useState<null | any[]>(null);
    const router = useRouter();
    const { t } = useTranslation("translation");
    const scrolled = useScrollingUp();

    const translationCounty: any = t("findRace.search.county", {}, { returnObjects: true });
    const county: any = {
        ...countryOptions,
        ...translationCounty,
    };

    const user = props.authUser;

    useEffect(() => {
        if (props.authUser) {
            props.firebase.logSignedIn(props.authUser, "user_data");
            setExternalUserId(props.authUser["uid"]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.authUser]);

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    };

    const handleLogin = () => {
        setOpenDrawer(false);
        setLogin(true);
    };

    useEffect(() => {
        if (openDrawer || login) {
            document.body.style.position = "fixed";
        } else {
            document.body.style.position = "initial";
        }
    }, [openDrawer, login]);

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!featuredRuns) {
                const result = await GetSubMenuData();
                const filteredData = await filterData(result.data, county);
                setFeaturedRuns(filteredData);

                const [runnersResult, organizerResult, newsResult] = await Promise.all([
                    GetBlogsForRunnersRequest(),
                    GetBlogsForOrganizersRequest(),
                    fetchBlogg(null, 15),
                ]);

                if (runnersResult.status === 200) set_runners_news(runnersResult.data);
                if (organizerResult.status === 200) set_organizer_news(organizerResult.data);

                set_news(newsResult);
            }
        };
        if (openDrawer) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openDrawer]);

    const GoHome = () => {
        if (router.asPath !== ROUTES.HOME) {
            router.push({ pathname: ROUTES.HOME });
        } else {
            window.scrollTo(0, 0);
        }
    };

    return (
        <>
            <Box
                sx={Object.assign(classes.root, scrolled ? classes.showHeader : classes.hideHeader)}
            >
                <Box sx={classes.navigation}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        alignContent="center"
                        pt={1}
                    >
                        <Grid item xs={4} sx={{ pl: 1 }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    cursor: "pointer",
                                }}
                            >
                                <IconButton
                                    aria-label="open drawer"
                                    edge="end"
                                    onClick={handleDrawerOpen}
                                >
                                    <Menu />
                                </IconButton>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            component="div"
                            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                        >
                            <Box onClick={GoHome} sx={{ pl: 1 }}>
                                <Monogram width="50" />
                            </Box>
                        </Grid>
                        <Grid item xs={4} sx={{ pl: 1 }}></Grid>

                        <Grid item xs={12} pt={1}>
                            <Box id="mobile-start">
                                <SearchComponent user={user} mobile={true} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Modal open={login} onClose={() => setLogin(false)}>
                <Box component="div">
                    <RegisterComponent onClose={() => setLogin(false)} login={true} />
                </Box>
            </Modal>

            <DrawerMenu
                open={openDrawer}
                handleDrawerClose={handleDrawerClose}
                handleLogin={handleLogin}
                authUser={user}
                mobile={true}
                featuredRuns={featuredRuns}
                news={news}
                organizer_news={organizer_news}
                runners_news={runners_news}
            />
        </>
    );
};

export default withFirebase(MobileHeader);
