import read from "./assets/read.jpg";
import running from "./assets/running.jpg";
import { convertDateString_EnglishToLocal } from "@/helpers/dateHelper";

export const UnpackBlogData = async (data: any, firebase: any, skipImages: boolean) => {
  let blogs: any = [];

  blogs = data.map((d: any) => {
    const temp: any = d;
    temp["key"] = d.id;
    temp["date"] = convertDateString_EnglishToLocal(new Date(d["date"]["_seconds"] * 1000).toDateString());
    return temp;
  });

  if (!skipImages) {
    for (let blogIndex = 0; blogIndex < blogs?.length; blogIndex++) {
      const imagesUrl: string[] = [];
      for (let i = 0; i < blogs[blogIndex]["img"]?.length; i++) {
        try {
          const tempUrl = blogs[blogIndex]["cms_created"] ? blogs[blogIndex]["img"][i]:await firebase.getImage(blogs[blogIndex]["img"][i]);
          imagesUrl.push(tempUrl);
        } catch {
          console.log("Did not find blog");
        }
      }
      if (imagesUrl.length > 0) {
        blogs[blogIndex]["img"] = imagesUrl;
      } else {
        blogs[blogIndex]["img"] = [blogIndex % 2 === 0 ? read : running];
      }
    }
  }
  return blogs;
};

export const UnpackBlogDataMeili = async (
  data: any,
  firebase: any,
  skipImages: boolean,
  limit?: number
) => {
  let blogs: any = data?.slice(0, limit ? limit : 9);
  if (!skipImages) {
    for (let blogIndex = 0; blogIndex < blogs?.length; blogIndex++) {
      if (blogs[blogIndex]["img"].length === 0 || blogs[blogIndex]["img"][0] === "") {
        blogs[blogIndex]["img"] = [blogIndex % 2 === 0 ? read.src : running.src];
      }

      try {
        blogs[blogIndex]["date"] = convertDateString_EnglishToLocal(new Date(blogs[blogIndex]["date"]["_seconds"] * 1000).toDateString());
      } catch { }
      blogs[blogIndex]["key"] = blogs[blogIndex]["_firestore_id"];
    }
  }
  return blogs;
};
